import { Flex, Box, Container } from '@chakra-ui/react';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Link } from '@/components/Link/Link';
import { Button } from '@/components/ui/molecules/Button';
import { Colors } from '@/components/ui/colors';
import { Text } from '@/components/ui/atoms/Text';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { useUserData } from '@/context/FirestoreContext';
import { useState, useEffect } from 'react';
import {
  VITE_CLAIM_REWARDS_MANTLE_USDC,
  VITE_VERCEL_ENV,
} from '@/config/constants';
import { USER_ENDPOINT, TELEGRAM_BATTLE_ENDPOINT } from '@/config/endpoints';
import { getShortenAddress } from '@/utils/utils';
import { isMobile } from '@/utils/isMobile';
import ConnectOkxWalletMobile from '@/components/earn/ConnectOkxWalletMobile';
import ConnectOkxWalletDesktopContainer from '@/components/earn/ConnectOkxWalletDesktop';
import { SECOND_PLAYER_ID } from 'genopets-utils';
import { useNavigate } from 'react-router-dom';
import MantleLogo from '../../images/wallets/mantle-logo.png';
import SolanaLogo from '../../images/wallets/solana-logo.png';
import ToncoinLogo from '../../images/wallets/toncoin-logo.png';
import { ConnectTonWallet } from './ConnectTonWallet';
import { ConnectSolanaWallet } from './ConnectSolanaWallet';

const MINIMUM_CLAIM_AMOUNT = 0.1;

enum WalletType {
  mantle,
  solana,
  ton,
}

class Wallet {
  type: WalletType;
  icon: string;
  label: string;
  value?: number;
  claimEndpoint: string;
  enabled: boolean;
  address?: string;
  walletName: string;
  explorerUrl: string;
  // claimStatus value could be "processing" or the claimed transaction hash
  claimStatus: string | undefined;
}

const WalletItem = ({
  wallet,
  isSelected,
  currency,
  bg,
  onSelect,
}: {
  wallet: Wallet;
  isSelected: boolean;
  currency: string;
  bg: string;
  onSelect: (type: WalletType) => void;
}) => (
  <Box
    position="relative"
    textAlign="center"
    cursor={wallet.enabled ? 'pointer' : 'not-allowed'}
    onClick={wallet.enabled ? () => onSelect(wallet.type) : undefined}
    flex="1"
  >
    <Box
      bg={isSelected ? bg : wallet.enabled ? Colors.Black : 'gray.700'}
      color={isSelected ? 'black' : 'white'}
      p={3}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <img
        src={wallet.icon}
        alt={wallet.label}
        style={{
          height: `32px`,
          marginBottom: '13px',
          width: '32px',
        }}
      />
      <Text color={isSelected ? 'Black' : 'White'} mb={'13px'}>
        {wallet.label}
      </Text>
      <Text color={isSelected ? 'Black' : 'White'}>
        {currency + (wallet.value ?? 0)}
      </Text>
    </Box>
    {isSelected && (
      <>
        <Box
          position="absolute"
          bottom="-14px"
          left="50%"
          transform="translateX(-50%)"
          width="0"
          height="0"
          borderLeft="12px solid transparent"
          borderRight="12px solid transparent"
          borderTop="14px solid black"
        />

        <Box
          position="absolute"
          bottom="-9px"
          left="50%"
          transform="translateX(-50%)"
          width="0"
          height="0"
          borderLeft="8px solid transparent"
          borderRight="8px solid transparent"
          borderTop={`12px solid ${bg}`}
        />
      </>
    )}
  </Box>
);

export const ClaimRewards = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(WalletType.mantle);
  const [isClaiming, setIsClaiming] = useState(false);
  const { apiPost, apiGet } = useAPI();
  const alert = useAlert();
  const { userData } = useUserData();
  const currency = '$';

  let mantleRewardsAmount = +(
    userData?.telegram?.mantleOkxCampaignRewardsAmount ?? 0
  );
  let mantleClaimEndpoint = `${USER_ENDPOINT}/claim-mantle-okx-reward`;
  let mantleClaimStatus = userData?.telegram?.mantleOkxCampaignRewardsStatus;

  // if mantle usdc rewards are enabled, use them instead
  if (VITE_CLAIM_REWARDS_MANTLE_USDC) {
    mantleRewardsAmount = userData?.telegram?.mantle_$USDC_rewardsAmount ?? 0;
    mantleClaimEndpoint = `${USER_ENDPOINT}/claim-mantle-usdc-reward`;
    mantleClaimStatus = userData?.telegram?.mantle_$USDC_rewardsStatus;
  }

  const solanaClaimStatus = userData?.telegram?.solana_$USDC_rewardsStatus;
  const tonClaimStatus = userData?.telegram?.ton_$JUSDC_rewardsStatus;

  const wallets: Record<WalletType, Wallet> = {
    [WalletType.mantle]: {
      icon: MantleLogo,
      type: WalletType.mantle,
      label: 'mantle',
      value: mantleClaimStatus ? 0 : mantleRewardsAmount,
      claimEndpoint: mantleClaimEndpoint,
      enabled: true,
      address: userData?.evmWallet,
      walletName: 'okx',
      claimStatus: mantleClaimStatus,
      explorerUrl:
        VITE_VERCEL_ENV === 'production'
          ? `https://explorer.mantle.xyz/tx/`
          : `https://explorer.sepolia.mantle.xyz/tx/`,
    },
    [WalletType.solana]: {
      icon: SolanaLogo,
      type: WalletType.solana,
      label: 'solana',
      value: solanaClaimStatus
        ? 0
        : userData?.telegram?.solana_$USDC_rewardsAmount,
      claimEndpoint: '',
      enabled: true,
      address: userData?.solanaAddressPixelton,
      walletName: 'sol',
      claimStatus: solanaClaimStatus,
      explorerUrl: '',
    },
    [WalletType.ton]: {
      icon: ToncoinLogo,
      type: WalletType.ton,
      label: 'ton',
      value: tonClaimStatus ? 0 : userData?.telegram?.ton_$JUSDC_rewardsAmount,
      claimEndpoint: `${USER_ENDPOINT}/claim-ton-reward`,
      enabled: true,
      address: userData?.tonWallet,
      walletName: 'ton',
      claimStatus: tonClaimStatus,
      explorerUrl:
        VITE_VERCEL_ENV === 'production'
          ? `https://tonviewer.com/transaction/`
          : `https://testnet.tonviewer.com/transaction/`,
    },
  };

  const isClaimProcessing =
    wallets[selectedWallet].claimStatus === 'processing';

  const rewardsTotal = Object.values(wallets).reduce(
    (sum, wallet) => sum + (wallet.value ?? 0),
    0,
  );

  const claimHash =
    !isClaimProcessing && wallets[selectedWallet].claimStatus
      ? wallets[selectedWallet].claimStatus
      : undefined;

  const claimThresholdMet =
    (wallets[selectedWallet]?.value ?? 0) >= MINIMUM_CLAIM_AMOUNT;

  console.log(claimThresholdMet);

  const backgroundColor = claimThresholdMet ? Colors.Yellow : Colors.White;

  const connectWalletButton = (type: WalletType): JSX.Element => {
    switch (type) {
      case WalletType.mantle:
        if (isMobile()) return <ConnectOkxWalletMobile />;
        return <ConnectOkxWalletDesktopContainer />;
      case WalletType.ton:
        return <ConnectTonWallet />;
      case WalletType.solana:
        return <ConnectSolanaWallet />;
      default:
        return <Container h={'20px'} />;
    }
  };

  const createBattle = async () => {
    if (isLoading) return;

    setIsLoading(true);
    apiPost(TELEGRAM_BATTLE_ENDPOINT, {
      opponent: SECOND_PLAYER_ID,
    })
      .then((resp) => {
        navigate(`/battle/${resp}`);
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (mantleRewardsAmount !== undefined) return;
    apiGet(`${USER_ENDPOINT}/mantle-okx-reward-eligibility`).catch(() =>
      alert({
        title: `Error verifying your eligibility. Pls re-open the app.`,
      }),
    );
  }, [apiGet, mantleRewardsAmount]);

  return (
    <Flex
      position={`absolute`}
      bottom={0}
      w={`full`}
      h={`auto`}
      flexDirection={'column'}
    >
      <Box>
        <Flex
          background={backgroundColor}
          w={`full`}
          padding={`0px`}
          flexDirection={`column`}
          gap={`8px`}
          border={`solid 3px black`}
          alignItems={`center`}
        >
          <Text colorId={`Black`} fontSize={`12px`}>
            Your reward
          </Text>
          <Text colorId={`Black`} fontSize={`24px`}>
            {rewardsTotal !== undefined ? (
              <Flex alignItems={`center`} gap={`8px`}>
                <span>{currency}</span>
                <span>{rewardsTotal.toFixed(2)}</span>
              </Flex>
            ) : (
              `checking...`
            )}
          </Text>
          {rewardsTotal !== undefined && !claimThresholdMet && (
            <Text colorId={`Red`} fontSize={`12px`}>
              Minimum {currency}
              {MINIMUM_CLAIM_AMOUNT} to claim
            </Text>
          )}
          {claimThresholdMet && mantleRewardsAmount !== undefined && (
            <Text colorId={`Black`} fontSize={`12px`}>
              {isClaimProcessing ? (
                `processing claim`
              ) : claimHash ? (
                <a
                  target="_blank"
                  href={`${wallets[selectedWallet].explorerUrl}${claimHash}`}
                  rel="noreferrer"
                  style={{ textDecoration: `underline` }}
                >
                  Claimed! See transaction
                </a>
              ) : (
                `ready to claim`
              )}
            </Text>
          )}
          <Flex flexDirection={'row'} gap={'3px'} w="100%">
            {Object.values(wallets).map((wallet) => (
              <WalletItem
                key={wallet.type}
                bg={backgroundColor}
                wallet={wallet}
                currency={currency}
                isSelected={selectedWallet === wallet.type}
                onSelect={(type) => setSelectedWallet(type)}
              />
            ))}
          </Flex>
        </Flex>

        <Box padding={`12px 12px 0`} background={`white`}>
          <Flex pb={'8px'} alignItems="center" justifyContent="center">
            <img
              src={wallets[selectedWallet].icon}
              style={{
                width: `100%`,
                maxWidth: `32px`,
              }}
            />
            <Text
              pl={'8px'}
              colorId={wallets[selectedWallet].address ? `DarkYellow` : `Red`}
              fontSize={`12px`}
            >
              {wallets[selectedWallet].address
                ? `${wallets[selectedWallet].walletName} wallet`
                : 'no wallet connected'}
            </Text>

            {wallets[selectedWallet].address && (
              <Text
                colorId={`Black`}
                fontSize={`12px`}
                style={{
                  flex: 1,
                  textAlign: 'right',
                }}
              >
                {getShortenAddress(wallets[selectedWallet].address ?? '', 4)}
              </Text>
            )}
          </Flex>
          {connectWalletButton(selectedWallet)}
          {!claimThresholdMet || claimHash ? (
            <Button
              colorId={`Yellow`}
              w={`full`}
              h={'46px'}
              border={`solid 3px black`}
              isDisabled={isLoading}
              onClick={createBattle}
              className="animate-color-cycle"
            >
              <Text colorId={`Black`}>Go battle!</Text>
            </Button>
          ) : (
            <Button
              colorId={`Yellow`}
              w={`full`}
              h={'46px'}
              border={`solid 3px black`}
              isDisabled={
                wallets[selectedWallet].value === undefined ||
                isClaiming ||
                !wallets[selectedWallet].address
              }
              onClick={async () => {
                try {
                  setIsClaiming(true);
                  await apiPost(wallets[selectedWallet].claimEndpoint, {});
                  alert({
                    title: `Claimed ${(wallets[selectedWallet].value ?? 0).toFixed(2)} ${currency}`,
                  });
                } catch (error: any) {
                  alert({ title: `Err: ${error?.message}` });
                  console.log(error);
                } finally {
                  setIsClaiming(false);
                }
              }}
            >
              <Text colorId={`Black`}>
                {isClaiming ? `processing...` : `claim reward!`}
              </Text>
            </Button>
          )}

          <Link to="/home" style={{ width: '100%' }}>
            <Button colorId="White" w="full" isDisabled={isLoading}>
              <TranslatedText
                translationKey={`goHome`}
                defaultMessage={`Go Home`}
              />
            </Button>
          </Link>
        </Box>
      </Box>
    </Flex>
  );
};
