import { Box, Center, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Text } from '../ui/atoms/Text';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Trophy } from '../ui/icons/Trophy';
import { Button } from '../ui/molecules/Button';
import { Card } from '../ui/molecules/Card';
import { useAirdropData } from './hooks/useAirdropData';
import { Link } from '@/components/Link/Link';
import { SummaryInfoRow } from '../ui/molecules/SummaryInfoRow';
import {
  USER_ADD_TON_WALLET_ENDPOINT,
  USER_REMOVE_TON_WALLET_ENDPOINT,
} from '@/config/endpoints';
import { useEffect, useState } from 'react';
import useAPI from '@/hooks/useAPI';
import { useUserData } from '@/context/FirestoreContext';
import {
  useTonConnectModal,
  useTonWallet,
  toUserFriendlyAddress,
  useTonConnectUI,
} from '@tonconnect/ui-react';
import { useAlert } from '@/hooks/useAlert';

const TIER_PREFIX = 'airdropTier';

export const AirdropDetails = () => {
  const navigate = useNavigate();
  const { open } = useTonConnectModal();

  const { amount, prizes } = useAirdropData();
  const alert = useAlert();
  const [tonConnectUI] = useTonConnectUI();
  const [isSaving, setIsSaving] = useState(false);

  const wallet = useTonWallet();
  const { apiPut } = useAPI();

  const connectWallet = async (walletAddress: string) => {
    try {
      setIsSaving(true);
      await apiPut(USER_ADD_TON_WALLET_ENDPOINT, {
        walletAddress,
      });
    } catch (error: any) {
      console.error(error);
      alert({ title: error?.message ?? 'Error' });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (wallet?.account.address) {
      connectWallet(wallet.account.address);
    }
  }, [wallet]);

  useEffect(() => {
    const unsubscribe = tonConnectUI.onStatusChange((wallet) => {
      // this is the event that gets fired after connecting wallet flow and goes back to home page
      if (wallet) {
        connectWallet(wallet.account.address);
      }
    });
    return () => unsubscribe();
  }, [tonConnectUI]);

  const { userData } = useUserData();
  const friendlyWalletAddress = userData?.tonWallet
    ? toUserFriendlyAddress(userData?.tonWallet)
    : undefined;

  return (
    <Card
      height={'full'}
      w="full"
      colorId="Yellow"
      justifyContent={'flex-start'}
    >
      <Box
        height={`calc(100% - ${userData?.tonWallet ? '188px' : '138px'})`}
        display={'flex'}
        position={'relative'}
        overflow={'auto'}
        justifyContent={'flex-start'}
        gap={4}
        flexDirection={'column'}
      >
        <Center w="full">
          <Trophy />
        </Center>
        <Text colorId="Black" textAlign={'center'}>
          $
          <TranslatedText
            translationKey={`amountInAirdropPrizes`}
            defaultMessage={`${amount} in airdrop prizes`}
            props={{ amount: amount ?? '...' }}
          />
          !
        </Text>
        <Text colorId="Black" textAlign={'center'}>
          <TranslatedText
            translationKey={`airdropPrizesArePaidInLiquidTokens`}
            defaultMessage={`prizes are paid in liquid tokens`}
          />
        </Text>
        {prizes ? (
          <>
            <Flex justifyContent="space-between" w="full">
              <Text colorId="Black" textAlign={'center'}>
                <TranslatedText
                  translationKey={`playerRank`}
                  defaultMessage={`player rank`}
                />
              </Text>
              <Text colorId="Black" textAlign={'center'}>
                <TranslatedText
                  translationKey={`prize`}
                  defaultMessage={`prize`}
                />
              </Text>
            </Flex>
            {prizes ? (
              <>
                <Box w="full">
                  {Object.entries(prizes).map(([tier, info], index) => (
                    <Box key={`${tier}-${index}`}>
                      <Text
                        textAlign={'center'}
                        color={info.color}
                        w="full"
                        mt={index === 0 ? 0 : 8}
                      >
                        <TranslatedText
                          translationKey={(TIER_PREFIX + tier) as any}
                          defaultMessage={tier + ' tier'}
                        />
                      </Text>
                      {info.isUniformPrize ? (
                        <SummaryInfoRow
                          key={index}
                          colorId="Black"
                          bgColorId="Yellow"
                          leftComponent={
                            <>
                              <Text colorId={`Black`}>
                                {Math.min(
                                  ...info.information.map(
                                    (inf) => inf.position,
                                  ),
                                ) +
                                  'th-' +
                                  Math.max(
                                    ...info.information.map(
                                      (inf) => inf.position,
                                    ),
                                  ) +
                                  'th'}
                              </Text>
                            </>
                          }
                          rightComponent={
                            <>
                              <Text
                                colorId={`Black`}
                              >{`$${info.information[0].amount}`}</Text>
                            </>
                          }
                        />
                      ) : (
                        <Box w="full">
                          {info.information
                            .sort((a, b) => a.position - b.position)
                            .map((elite) => (
                              <SummaryInfoRow
                                key={elite.position}
                                colorId="Black"
                                bgColorId="Yellow"
                                leftComponent={
                                  <>
                                    <Text colorId={`Black`}>
                                      {elite.position === 1
                                        ? '1st'
                                        : elite.position === 2
                                          ? '2nd'
                                          : `${elite.position}th`}
                                    </Text>
                                  </>
                                }
                                rightComponent={
                                  <>
                                    <Text
                                      colorId={`Black`}
                                    >{`$${elite.amount}`}</Text>
                                  </>
                                }
                              />
                            ))}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            ) : null}
          </>
        ) : (
          <>
            <Text colorId="Black" textAlign={'center'}>
              <TranslatedText
                translationKey={`loading`}
                defaultMessage={`loading`}
              />
              ...
            </Text>
          </>
        )}
      </Box>
      <Box position={'absolute'} bottom={4} left={4} right={4}>
        <Button
          colorId="Black"
          w="full"
          onClick={() => {
            open();
          }}
          isDisabled={!!userData?.tonWallet || isSaving}
        >
          {userData?.tonWallet && friendlyWalletAddress ? (
            <Text textTransform={`none`}>
              {friendlyWalletAddress.substring(0, 4)}...
              {friendlyWalletAddress.substring(
                friendlyWalletAddress.length - 4,
              )}
            </Text>
          ) : (
            <TranslatedText
              translationKey={`connectWallet`}
              defaultMessage={`connect wallet`}
            />
          )}
        </Button>

        {userData?.tonWallet && (
          <Button
            colorId={`Yellow`}
            w="full"
            disabled={isSaving}
            padding={`12px`}
            onClick={async () => {
              try {
                setIsSaving(true);
                await apiPut(USER_REMOVE_TON_WALLET_ENDPOINT, {}, true);
                await tonConnectUI.disconnect();
              } catch (error: any) {
                console.error(error);
                alert({ title: error?.message ?? 'Error' });
              } finally {
                setIsSaving(false);
              }
            }}
          >
            <Text colorId={`Black`}>
              <TranslatedText
                translationKey={`disconnectWallet`}
                defaultMessage={`disconnect wallet`}
              />
            </Text>
          </Button>
        )}

        <Link
          to="https://www.genopets.me/articles/pixelton-arena-airdrop-dollar100000-in-prizes-up-for-grabs-cm0bfr5b2008de3aoe9y9xlmb"
          style={{ width: '100%' }}
        >
          <Button colorId="Yellow" w="full" color="var(--Black)">
            <TranslatedText
              translationKey={`termsAndConditions`}
              defaultMessage={`terms And Conditions`}
            />
          </Button>
        </Link>
        <Button
          colorId="Yellow"
          color="var(--Black)"
          w="full"
          onClick={() => {
            navigate(-1);
          }}
        >
          <TranslatedText
            translationKey={`goBack`}
            defaultMessage={`go Back`}
          />
        </Button>
      </Box>
    </Card>
  );
};
