import { Box } from '@chakra-ui/react';
import { Text } from '@/components/ui/atoms/Text';
import { Button } from '@/components/ui/molecules/Button';
import { MANTLE_MAINNET_CHAIN_ID } from '@/config/constants';
import { useUserData } from '@/context/FirestoreContext';
import { useState, useEffect } from 'react';
import {
  USER_ADD_EVM_WALLET_ENDPOINT,
  USER_REMOVE_EVM_WALLET_ENDPOINT,
} from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { OKXUniversalProvider } from '@okxconnect/universal-provider';

const ConnectOkxWalletMobile = () => {
  const { apiPut } = useAPI();
  const alert = useAlert();
  const { userData } = useUserData();

  const [okxProvider, setOkxProvider] = useState<OKXUniversalProvider>();
  const [session, setSession] = useState<any>();
  const evmWalletAddress =
    session?.namespaces?.eip155?.accounts[0]?.split(`:`)?.[2];
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!okxProvider) {
      OKXUniversalProvider.init({
        dappMetaData: {
          name: 'Pixelton Arena',
          icon: 'https://cdn.genopets.me/Pixelton/pixelton-arena-title.png',
        },
      }).then((provider) => {
        setOkxProvider(provider);
        provider.setDefaultChain(
          `eip155:${MANTLE_MAINNET_CHAIN_ID}`,
          'https://rpc.mantle.xyz',
        );
      });
    }
  }, [okxProvider]);

  const connectWallet = async () => {
    if (!okxProvider) return;

    const sessionConnect = await okxProvider.connect({
      namespaces: {
        eip155: {
          chains: [`eip155:${MANTLE_MAINNET_CHAIN_ID}`],
          rpcMap: {
            [MANTLE_MAINNET_CHAIN_ID]: 'https://rpc.mantle.xyz',
          },
          defaultChain: String(MANTLE_MAINNET_CHAIN_ID),
        },
      },
      sessionConfig: {
        redirect: 'tg://resolve',
      },
    });

    setSession(sessionConnect);
  };

  // once the user has connected their wallet, save it to their game account
  useEffect(() => {
    // exit if no wallet is connected
    if (!evmWalletAddress) return;

    // if new wallet has been connected, save it to their game account
    if (userData?.evmWallet !== evmWalletAddress) {
      apiPut(USER_ADD_EVM_WALLET_ENDPOINT, {
        walletAddress: evmWalletAddress,
      })
        .then(() => {
          alert({ title: `wallet connected` });
        })
        .catch((error) => {
          alert({ title: `Err: ${error?.message}` });
        });
    }
  }, [evmWalletAddress, userData?.evmWallet]);

  if (!okxProvider) return null;

  return (
    <>
      {userData?.evmWallet ? (
        <Box w={`full`}>
          <Button
            mb={2}
            colorId={`Black`}
            w={`full`}
            h={'46px'}
            border={`solid 3px black`}
            isDisabled={isSaving}
            onClick={async () => {
              try {
                setIsSaving(true);
                await apiPut(USER_REMOVE_EVM_WALLET_ENDPOINT, {});
                alert({ title: `wallet disconnected` });
              } catch (error: any) {
                alert({ title: `Err: ${error?.message}` });
              } finally {
                setIsSaving(false);
              }
            }}
          >
            <Text colorId={`White`}>disconnect</Text>
          </Button>
        </Box>
      ) : (
        <Button
          mb={2}
          borderLeft={`1px solid black`}
          colorId={`Black`}
          padding={`8px`}
          minWidth={`0px`}
          w={`full`}
          h={'46px'}
          isDisabled={isSaving}
          onClick={connectWallet}
        >
          <Text colorId={`White`}>Connect okx wallet</Text>
        </Button>
      )}
    </>
  );
};

export default ConnectOkxWalletMobile;
