import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
} from 'react';
import {
  useTonConnectModal,
  useTonWallet,
  useTonConnectUI,
} from '@tonconnect/ui-react';
import useAPI from '@/hooks/useAPI';
import { USER_ADD_TON_WALLET_ENDPOINT } from '@/config/endpoints';

type TaskLauncher = (taskLink: string) => Promise<void>;
type TaskChecker = () => Promise<boolean>;

interface ProtocolHandler {
  taskLauncher: TaskLauncher;
  taskChecker: TaskChecker;
}

interface TaskVerificationContextType {
  processTask: (task: string) => Promise<void>;
  verifyTask: (task: string) => Promise<boolean>;
  getProtocolHandler: (task: string) => ProtocolHandler;
}

const TaskVerificationContext = createContext<
  TaskVerificationContextType | undefined
>(undefined);

export const TaskVerificationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { open } = useTonConnectModal();
  const [TonConnectUI] = useTonConnectUI();

  const wallet = useTonWallet();

  const { apiPut } = useAPI();

  const addTonWallet = useCallback(async () => {
    if (wallet) {
      console.log('adding wallet to game-account: ', wallet.account.address);
      await apiPut(USER_ADD_TON_WALLET_ENDPOINT, {
        walletAddress: wallet.account.address,
      });
    }
  }, [apiPut, wallet]);

  const protocolHandlers: Record<string, ProtocolHandler> = {
    'ton-connect': {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      taskLauncher: async (_taskLink: string) => {
        // if there is already a wallet connected already, disconnect it
        // first to allow the user to connect a different wallet
        if (wallet) {
          await TonConnectUI.disconnect();
          console.log(
            'TON wallet already connected. disconnecting wallet: ',
            wallet.account.address,
          );
        }

        open();
      },
      taskChecker: async () => {
        if (wallet) {
          await addTonWallet();

          return true;
        }
        return false;
      },
    },
  };

  const defaultHandler: ProtocolHandler = {
    taskLauncher: async (taskLink: string) => {
      window.open(taskLink, '_blank');
    },
    taskChecker: async () => {
      return new Promise<boolean>((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 5000);
      });
    },
  };

  function getProtocolHandler(taskLink: string): ProtocolHandler {
    try {
      const protocol = new URL(taskLink).protocol.slice(0, -1);
      return protocolHandlers[protocol] || defaultHandler;
    } catch (error) {
      console.error('Invalid task link:', taskLink);
      return defaultHandler;
    }
  }

  async function processTask(taskLink: string): Promise<void> {
    const handler = getProtocolHandler(taskLink);
    await handler.taskLauncher(taskLink);
  }

  async function verifyTask(taskLink: string): Promise<boolean> {
    const handler = getProtocolHandler(taskLink);
    return handler.taskChecker();
  }

  return (
    <TaskVerificationContext.Provider
      value={{ getProtocolHandler, processTask, verifyTask }}
    >
      {children}
    </TaskVerificationContext.Provider>
  );
};

export const useTaskVerification = () => {
  const context = useContext(TaskVerificationContext);
  if (context === undefined) {
    throw new Error(
      'useTaskVerification must be used within a TaskVerificationProvider',
    );
  }
  return context;
};
