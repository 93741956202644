import { useUserData } from '@/context/FirestoreContext';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Button } from '../ui/molecules/Button';
import {
  USER_ADD_SOLANA_WALLET_ENDPOINT,
  USER_REMOVE_SOLANA_WALLET_ENDPOINT,
} from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useEffect, useState } from 'react';
import { OKXUniversalConnectUI } from '@okxconnect/ui';

const SOLANA_CHAIN_ID = `solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp`; // mainnet

export const ConnectSolanaWallet = () => {
  const { userData } = useUserData();
  const [isSaving, setIsSaving] = useState(false);
  const { apiPut } = useAPI();
  const [universalUiProvider, setUniversalUiProvider] = useState<any>();
  const [session, setSession] = useState<any>();
  const solanaWalletAddress =
    session?.namespaces?.solana?.accounts[0]?.split(`:`)?.[2];

  const addWallet = async (walletAddress: string) => {
    try {
      setIsSaving(true);
      await apiPut(USER_ADD_SOLANA_WALLET_ENDPOINT, {
        walletAddress,
      });
    } catch (error: any) {
      console.error(error);
      alert({ title: error?.message ?? 'Error' });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (!universalUiProvider) {
      OKXUniversalConnectUI.init({
        dappMetaData: {
          icon: 'https://cdn.genopets.me/Pixelton/pixelton-arena-title.png',
          name: 'Pixelton Arena',
        },
        actionsConfiguration: {
          returnStrategy: 'tg://resolve',
          modals: 'all',
          tmaReturnUrl: 'back',
        },
      }).then((universalConnectUi) => {
        setUniversalUiProvider(universalConnectUi);
      });
    }
  }, [universalUiProvider]);

  const connectWallet = async () => {
    if (!universalUiProvider) return;

    const session = await universalUiProvider.openModal({
      namespaces: {
        solana: {
          chains: [SOLANA_CHAIN_ID],
        },
      },
    });
    setSession(session);
  };

  useEffect(() => {
    if (
      solanaWalletAddress &&
      userData?.solanaAddressPixelton !== solanaWalletAddress
    ) {
      addWallet(solanaWalletAddress);
    }
  }, [solanaWalletAddress, userData?.solanaAddressPixelton]);

  return userData?.solanaAddressPixelton ? (
    <Button
      mb={2}
      w="full"
      h={'46px'}
      colorId={`Black`}
      isDisabled={isSaving}
      whiteSpace={'pre-wrap'}
      onClick={async () => {
        try {
          setIsSaving(true);
          await apiPut(USER_REMOVE_SOLANA_WALLET_ENDPOINT, {}, true);
          await universalUiProvider?.disconnect();
          setSession(undefined);
        } catch (error: any) {
          console.error(error);
          alert({ title: error?.message ?? 'Error' });
        } finally {
          setIsSaving(false);
        }
      }}
    >
      <TranslatedText
        translationKey={`disconnect`}
        defaultMessage={`disconnect`}
      />
    </Button>
  ) : (
    <Button
      mb={2}
      w="full"
      h={'46px'}
      colorId={`Black`}
      isDisabled={isSaving}
      whiteSpace={'pre-wrap'}
      onClick={connectWallet}
    >
      <TranslatedText
        translationKey={`connectWallet`}
        defaultMessage={`connect wallet`}
      />
    </Button>
  );
};
