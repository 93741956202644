import { useDisclosure, Flex, Box } from '@chakra-ui/react';
import { Text } from '../ui/atoms/Text';
import { Modal } from '../ui/molecules/Modal';
import useAPI from '@/hooks/useAPI';
import { useUserData } from '@/context/FirestoreContext';
import { Button } from '@/components/ui/molecules/Button';
import {
  toUserFriendlyAddress,
  useTonWallet,
  useTonConnectUI,
  useTonConnectModal,
} from '@tonconnect/ui-react';
import {
  USER_ADD_TON_WALLET_ENDPOINT,
  USER_REMOVE_TON_WALLET_ENDPOINT,
  USER_REMOVE_EVM_WALLET_ENDPOINT,
} from '@/config/endpoints';
import { useAlert } from '@/hooks/useAlert';
import { useState, useEffect } from 'react';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { useEffectPlayer } from '@/context/EffectPlayerContext';
import { useMusicPlayer } from '@/context/MusicPlayerContext';
import SettingsImg from '../../images/settings.png';
import { TonLogo } from '../ui/icons/TonLogo';
import { Mute } from '../ui/icons/Mute';
import { getShortenAddress } from '@/utils/utils';
import { Okx } from '../ui/icons/Okx';
import { useWallets } from '@privy-io/react-auth';
import PrivyWalletProvider from '../auth/PrivyWalletProvider';
import { VITE_PRIVY_WALLET_ENABLED } from '@/config/constants';

const SettingsCard = ({
  logo,
  children,
  button,
}: {
  logo: React.ReactNode;
  children: React.ReactNode;
  button: React.ReactNode;
}) => {
  const borderStyle = `3px solid var(--Black, #000)`;

  return (
    <Box w="full" border={borderStyle} p={1}>
      <Flex mb={4}>
        <Flex alignItems="center">
          <Box
            width="2.5rem"
            height="2.5rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
            mr={2}
          >
            {logo}
          </Box>
          <Flex flexDirection="column" flex={1}>
            {children}
          </Flex>
        </Flex>
      </Flex>
      {button}
    </Box>
  );
};

const EvmWalletConnected = () => {
  const { apiPut } = useAPI();
  const alert = useAlert();
  const { userData } = useUserData();
  const { wallets } = useWallets();

  const evmWallet = wallets.find(
    (wallet) =>
      (wallet as any).type === 'ethereum' &&
      wallet.chainId.startsWith(`eip155`) &&
      wallet.connectorType !== `embedded`,
  );
  return (
    <SettingsCard
      logo={<Okx />}
      button={
        <Button
          w="full"
          colorId={`Black`}
          whiteSpace={'pre-wrap'}
          onClick={async () => {
            try {
              await apiPut(USER_REMOVE_EVM_WALLET_ENDPOINT, {});
              evmWallet?.disconnect();
            } catch (error: any) {
              alert({ title: error?.message });
            }
          }}
        >
          <TranslatedText
            translationKey={`disconnect`}
            defaultMessage={`disconnect`}
          />
        </Button>
      }
    >
      <>
        <Flex>
          <Text colorId="Black" mr={2}>
            <TranslatedText
              translationKey="okxWallet"
              defaultMessage="OKX Wallet"
            />
          </Text>
        </Flex>
        <Text colorId="Green">
          <TranslatedText
            translationKey={`connected`}
            defaultMessage={`connected`}
          />
        </Text>

        <Text colorId={`Black`}>
          {getShortenAddress(userData?.evmWallet ?? '', 5)}
        </Text>
      </>
    </SettingsCard>
  );
};

const EvmWalletSettingsCard = () => {
  // must not initialize PrivyWalletProvider if feature flag is off so that it doesn't create the user on the background
  if (!VITE_PRIVY_WALLET_ENABLED) return null;

  return (
    <PrivyWalletProvider>
      <EvmWalletConnected />
    </PrivyWalletProvider>
  );
};

export const ManageWalletModal = () => {
  const alert = useAlert();
  const [isSaving, setIsSaving] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { apiPut } = useAPI();
  const { userData } = useUserData();
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const { open: openConnectTonWalletModal } = useTonConnectModal();

  const friendlyWalletAddress = userData?.tonWallet
    ? toUserFriendlyAddress(userData?.tonWallet)
    : undefined;

  const addWallet = async (walletAddress: string) => {
    try {
      setIsSaving(true);
      await apiPut(USER_ADD_TON_WALLET_ENDPOINT, {
        walletAddress,
      });
    } catch (error: any) {
      console.error(error);
      alert({ title: error?.message ?? 'Error' });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (wallet?.account.address) {
      addWallet(wallet?.account.address);
    }
  }, [wallet]);

  const musicPlayer = useMusicPlayer();
  const effectPlayer = useEffectPlayer();

  useEffect(() => {
    const unsubscribe = tonConnectUI.onStatusChange((wallet) => {
      // this is the event that gets fired after connecting wallet flow and goes back to home page
      if (wallet) {
        console.log(`wallet connected`, wallet);
        // open modal again after connecting wallet
        onOpen();

        addWallet(wallet.account.address);
      } else {
        console.log(`wallet not connected`);
      }
    });
    return () => unsubscribe();
  }, [tonConnectUI]);

  return (
    <>
      <Button
        padding={`8px`}
        height={`60px`}
        width={`60px`}
        minWidth={`inherit`}
        border="3px solid var(--Black)"
        background={`var(--White)`}
        onClick={() => {
          onOpen();
        }}
      >
        <Flex justifyContent={`center`} alignItems={`center`} display={`flex`}>
          <img
            src={SettingsImg}
            alt="settings"
            style={{ width: 'auto', height: 'auto' }}
          />
        </Flex>
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        showBackButton={false}
        contentProps={{ maxWidth: `320px` }}
        cardProps={{ padding: `4px` }}
      >
        <Flex
          justifyContent={`center`}
          flexDirection={`column`}
          alignItems={`flex-start`}
          gap={2}
          padding={1}
          w="full"
        >
          <Flex>
            <Flex justifyContent={`center`} alignItems={`center`} gap={2}>
              <img
                src={SettingsImg}
                alt="settings"
                style={{ width: 'auto', height: 'auto' }}
              />
              <Text colorId="Black">
                <TranslatedText
                  translationKey={`settings`}
                  defaultMessage={`settings`}
                />
              </Text>
            </Flex>
          </Flex>
          <SettingsCard
            logo={<TonLogo />}
            button={
              userData?.tonWallet ? (
                <Button
                  w="full"
                  colorId={`Black`}
                  isDisabled={isSaving}
                  whiteSpace={'pre-wrap'}
                  onClick={async () => {
                    try {
                      setIsSaving(true);
                      await apiPut(USER_REMOVE_TON_WALLET_ENDPOINT, {}, true);
                      await tonConnectUI.disconnect();
                    } catch (error: any) {
                      console.error(error);
                      alert({ title: error?.message ?? 'Error' });
                    } finally {
                      setIsSaving(false);
                    }
                  }}
                >
                  <TranslatedText
                    translationKey={`disconnect`}
                    defaultMessage={`disconnect`}
                  />
                </Button>
              ) : (
                <Button
                  w="full"
                  colorId={`Black`}
                  isDisabled={isSaving}
                  whiteSpace={'pre-wrap'}
                  onClick={async () => {
                    // open connect ton wallet drawer
                    openConnectTonWalletModal();

                    // close wallet management modal when opening connect wallet drawer because of overlay issue (zIndex)
                    // the modal will open again after connecting
                    onClose();
                  }}
                >
                  <TranslatedText
                    translationKey={`connectWallet`}
                    defaultMessage={`connect wallet`}
                  />
                </Button>
              )
            }
          >
            <Flex>
              <Text colorId="Black" mr={2}>
                <TranslatedText
                  translationKey="airdrop"
                  defaultMessage="airdrop"
                />
              </Text>
              <Text colorId="Black" mr={2}>
                <TranslatedText
                  translationKey="wallet"
                  defaultMessage="wallet"
                />
              </Text>
            </Flex>
            {userData?.tonWallet ? (
              <>
                <Text colorId="Green">
                  <TranslatedText
                    translationKey={`connected`}
                    defaultMessage={`connected`}
                  />
                </Text>
                {friendlyWalletAddress && (
                  <Text colorId={`Black`}>
                    {friendlyWalletAddress.substring(0, 4)}...
                    {friendlyWalletAddress.substring(
                      friendlyWalletAddress.length - 4,
                    )}
                  </Text>
                )}
              </>
            ) : (
              <Text colorId="Red">
                <TranslatedText
                  translationKey={`notConnected`}
                  defaultMessage={`not connected`}
                />
              </Text>
            )}
          </SettingsCard>

          {/*should only render component if modal is opened,
             so it doesn't automatically create users on the background unnecessarily*/}
          {/* 
          // commenting this out since we do not want to allow a user to connect a evm wallet from
          // here until further notice.
          {isOpen && <ManagePrivyWallet />} 
          */}

          {userData?.evmWallet && <EvmWalletSettingsCard />}

          <SettingsCard
            logo={
              <Box
                bg="black"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                p={1}
              >
                <Mute />
              </Box>
            }
            button={
              <Button
                w="full"
                colorId={`Black`}
                isDisabled={isSaving}
                whiteSpace={'pre-wrap'}
                onClick={async () => {
                  musicPlayer.toggleMute();
                  effectPlayer.toggleMute();
                }}
              >
                <TranslatedText
                  translationKey={musicPlayer.muted ? `turnOn` : `turnOff`}
                  defaultMessage={`turn ${musicPlayer.muted ? 'on' : 'off'}`}
                />
              </Button>
            }
          >
            <Flex flexDirection="column">
              <Text colorId="Black">
                <TranslatedText
                  translationKey={`sound`}
                  defaultMessage={`sound`}
                />
              </Text>
              <Text colorId={musicPlayer.muted ? 'Red' : 'Green'}>
                <TranslatedText
                  translationKey={musicPlayer.muted ? `off` : `on`}
                  defaultMessage={musicPlayer.muted ? `Off` : `On`}
                />
              </Text>
            </Flex>
          </SettingsCard>

          <Button
            w="full"
            p={2}
            colorId={`White`}
            onClick={() => {
              onClose();
            }}
          >
            <TranslatedText translationKey={`close`} defaultMessage={`close`} />
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
