import { LeaderboardTimeframe } from 'genopets-utils';

export const API_ROOT = import.meta.env.VITE_API_ROOT;

// USER ENDPOINTS
export const USER_EMIT_EVENT_ENDPOINT = `${API_ROOT}/user/emit-event`;
export const USER_ENDPOINT = `${API_ROOT}/user`;
export const USER_ADD_EVM_WALLET_ENDPOINT = `${USER_ENDPOINT}/add-evm-wallet`;
export const USER_REMOVE_EVM_WALLET_ENDPOINT = `${USER_ENDPOINT}/remove-evm-wallet`;
export const USER_MANTLE_CHECK_IN_ENDPOINT = `${USER_ENDPOINT}/telegram-mantle-check-in`;
export const USER_CREATE_MANTLE_CHECK_IN_TASK_ENDPOINT = `${USER_ENDPOINT}/create-telegram-mantle-check-in-task`;
export const USER_ADD_TON_WALLET_ENDPOINT = `${USER_ENDPOINT}/add-ton-wallet`;
export const USER_REMOVE_TON_WALLET_ENDPOINT = `${USER_ENDPOINT}/remove-ton-wallet`;
export const USER_ADD_SOLANA_WALLET_ENDPOINT = `${USER_ENDPOINT}/add-solana-wallet`;
export const USER_REMOVE_SOLANA_WALLET_ENDPOINT = `${USER_ENDPOINT}/remove-solana-wallet`;
export const USER_CLAIM_TELEGRAM_DAILY_LOGIN_REWARDS_ENDPOINT = `${USER_ENDPOINT}/claim-telegram-daily-login-reward`;
export const USER_CLAIM_TELEGRAM_BATTLE_DAY_STREAK_REWARDS_ENDPOINT = `${USER_ENDPOINT}/claim-telegram-battle-day-streak-reward`;
export const USER_CLAIM_TELEGRAM_FEED_DAY_STREAK_REWARDS_ENDPOINT = `${USER_ENDPOINT}/claim-telegram-feed-day-streak-reward`;

// BATTLE ENDPOINTS
export const TELEGRAM_BATTLE_ENDPOINT = `${API_ROOT}/telegram-battles`;
export const TELEGRAM_BATTLE_HISTORY_ENDPOINT = `${TELEGRAM_BATTLE_ENDPOINT}/history`;
export const JOIN_BATTLE_ENDPOINT = `${TELEGRAM_BATTLE_ENDPOINT}/join`;
export const ADD_CPU_TO_BATTLE_ENDPOINT = `${TELEGRAM_BATTLE_ENDPOINT}/cpu-join`;
export const ACTION_BATTLE_ENDPOINT = (id?: string) =>
  `${TELEGRAM_BATTLE_ENDPOINT}/${id}/action`;
export const FORCE_ACTION_BATTLE_ENDPOINT = (id?: string) =>
  `${TELEGRAM_BATTLE_ENDPOINT}/${id}/force-action`;

// PET ENDPOINTS
export const PET_ENDPOINT = `${API_ROOT}/pet`;
export const RENAME_PET_ENDPOINT = `${PET_ENDPOINT}/rename`;
export const TELEGRAM_PET_ENDPOINT = `${PET_ENDPOINT}/telegram`;

// MOVES ENDPOINTS
export const MOVES_ALL_ENDPOINT = `${API_ROOT}/moves?type=telegram`;
export const LEARN_NEW_MOVE_ENDPOINT = `${API_ROOT}/game/telegram-learn-move`;

// ENERGY ENDPOINTS
export const CONVERT_ENERGY_TO_XP_ENDPOINT = `${API_ROOT}/game/telegram-energy-to-xp`;

// GAME SETTINGS
export const GAME_SETTINGS_ENDPOINT = `${API_ROOT}/game`;
export const SPIN_WHEEL_SETTINGS_ENDPOINT = `${API_ROOT}/game/spin-wheel`;

// GET REFERRAL LINK ENDPOINT
export const REFERRAL_LINK_ENDPOINT = `${API_ROOT}/invite-codes/refer-telegram`;

// TELEGRAM ENDPOINT
export const NUDGE_THEM_ENDPOINT = (id?: string) =>
  `${TELEGRAM_BATTLE_ENDPOINT}/${id}/nudge`;

// LEADERBOARD ENDPOINT
export const LEADERBOARD_ENDPOINT = (
  page: number,
  timeframe: LeaderboardTimeframe,
) =>
  `${API_ROOT}/game/leaderboard-energy-points?page=${page}&isTelegram=true&timeframe=${timeframe}`;

// TASKS
export const TELEGRAM_TASKS_ENDPOINT = `${API_ROOT}/game/telegram-tasks`;
export const USER_ENERGY_POINTS_SUMMARY = `${API_ROOT}/game/energy-points-summary`;
export const UPDATE_USER_TELEGRAM_TASK_RECORD_ENDPOINT = `${API_ROOT}/game/user-telegram-task-record`;
export const TELEGRAM_WATCH_AD_REWARD_ENDPOINT = `${API_ROOT}/game/telegram-watch-ad-reward`;

// IAP
export const PURCHASE_TICKETS_ENDPOINT = `${API_ROOT}/iap/purchase-tickets`;
export const PURCHASE_DAILY_TICKETS_WITH_MULTIPLIER_ENDPOINT = `${API_ROOT}/iap/purchase-daily-tickets-with-multiplier`;

export const CLAIM_DAILY_TICKETS_ENDPOINT = `${API_ROOT}/user/claim-telegram-daily-tickets`;

// airdrop
export const AIRDROP_PRIZES_ENDPOINT = `${API_ROOT}/telegram-airdrops`;

// feed to earn
export const FEED_TO_EARN_ENDPOINT = `${API_ROOT}/eat-to-earn`;
export const FEED_TO_EARN_FEED_ENDPOINT = `${FEED_TO_EARN_ENDPOINT}/feed`;
export const FEED_TO_EARN_PRESIGNED_URL_ENDPOINT = `${FEED_TO_EARN_ENDPOINT}/presigned-url`;
export const FEED_TO_EARN_INGREDIENTS_ENDPOINT = `${FEED_TO_EARN_ENDPOINT}/ingredients`;
